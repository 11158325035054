import { useState } from "react";
import { Row } from "reactstrap";
import LogoImage from '../../template/svg/GoDoLogo2.png';
import { Container, Logo, LogoContainer, MainContainer } from "./styled";
import Flags from './flagscsa';
import lockImage from '../../template/images/Lock.png';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import PopAlert from "./PopAlert";
import { MoroccoForm } from "./MoroccoForm";
import { Trans, useTranslation } from 'react-i18next';
import LanguageTranslation from "./LanguageTranslation";

function MoroccoScs() {
  const [flagData, setFlagData] = useState('FSA_MA');
  const [show, setShow] = useState(false);
  const [backPage, setBackPage] = useState();
  const [entityName, setEntityName] = useState('');
  const { t, i18n } = useTranslation();
  // Function to update flag data
  const updateFlagData = (newText) => {
    setFlagData(newText);
  };

  return (
    <MainContainer>
      <Row className="header" style={{ padding: "20px 0px", margin: "0" }}>
        <LogoContainer>
          <Logo src={LogoImage} className="logoCss" />
          <div className="d-flex align-items-center">
            <LanguageTranslation className='d-sm-block d-none' />
            <Flags updateFlagData={updateFlagData} entityName={setEntityName} entityUpdate={setBackPage} popUp={setShow} />
          </div>
        </LogoContainer>
        <LanguageTranslation className='w-100 d-block d-sm-none' />
      </Row>
      <Row>
        <PopAlert show={show} entity={setBackPage} entityName={entityName} entityChangeValue={flagData} popUp={setShow} />
      </Row>
      <Row className="top_security w-100">
        <p style={{ marginBottom: '0px', fontSize: '16px' }} className={`top_sec_p d-flex align-items-center justify-content-center ${i18n.language === 'ar' ? 'flex-row-reverse' : ''}`}>
          <img
            src={lockImage}
            style={{ marginTop: '-3px', width: '20px', marginRight: i18n.language !== 'ar' ? '15px' : 0, marginLeft: i18n.language === 'ar' ? '15px' : 0 }}
            alt="Lock Icon"
          />
          {t('Title')}
        </p>
      </Row>
      <Row className={`mt_50 w-100 ${i18n.language === 'ar' || i18n.language === 'fe' ? 'fs-5' : ''}`} style={{ margin: "50px 0", marginBottom: "0px", direction: `${i18n.language === 'ar' || i18n.language === 'fe' ? 'rtl' : ''}` }}>
        <h1 className="text-center fs-5 mb-4">{t('SubTitle')}</h1>
        <p className="headingBlock">
          <Trans i18nKey="Heading">
            <b>"GODO Capital Limited”</b>
            <b>Saint Vincent and the Grenadines.</b>
            <b>Financial Services Authority (FSA)</b>
          </Trans>
        </p>
      </Row>
      <Row className="text-center w-100">
        <h1 className="secondary">{t('ApplicationForm')}</h1>
        <p className="primary fw-bold fs-6">{t('Quote')}</p>
      </Row>
      <Container>
        <MoroccoForm entity={flagData} mainPage={backPage} />
      </Container>
      <div className="footer" style={{ direction: i18n.language === 'ar' || i18n.language === 'fe' ? 'rtl' : 'ltr', fontSize: i18n.language === 'ar' && '18px' }}>
        <div>
          <p className="text-center">{t('ClientService')}</p>
          <p>
            {t('RiskWarning')}
          </p>
        </div>
      </div>

      <ToastContainer />
    </MainContainer>
  );
}

export default MoroccoScs;